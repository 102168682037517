import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex text-xs" }
const _hoisted_2 = { class: "mr-1" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "flex text-xs" }
const _hoisted_5 = { class: "mr-1" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "flex text-xs" }
const _hoisted_8 = { class: "mr-1" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = {
  key: 0,
  class: "flex text-xs"
}
const _hoisted_11 = { class: "mr-1" }
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = {
  key: 1,
  class: "flex text-xs"
}
const _hoisted_14 = { class: "mr-1" }
const _hoisted_15 = { class: "font-bold" }
const _hoisted_16 = { class: "flex text-xs" }
const _hoisted_17 = { class: "mr-1" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "flex text-xs" }
const _hoisted_20 = { class: "mr-1" }
const _hoisted_21 = { class: "font-bold" }
const _hoisted_22 = { class: "flex text-xs" }
const _hoisted_23 = { class: "mr-1" }
const _hoisted_24 = { class: "font-bold" }
const _hoisted_25 = { class: "flex text-xs" }
const _hoisted_26 = { class: "mr-1" }
const _hoisted_27 = { class: "font-bold" }
const _hoisted_28 = { class: "flex text-xs" }
const _hoisted_29 = { class: "mr-1" }
const _hoisted_30 = { class: "font-bold" }
const _hoisted_31 = { class: "flex text-xs" }
const _hoisted_32 = { class: "mr-1" }
const _hoisted_33 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('darkstoreSettings.email_address')) + ":", 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.settings[0]?.email_address ?? 'N/A'), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('darkstoreSettings.max_threshold_waiting_time')) + ":", 1),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.settings[0]?.max_threshold_waiting_time ?? 'N/A'), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('darkstoreSettings.max_orders_assigned')) + ":", 1),
      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.settings[0]?.max_orders_assigned ?? 'N/A'), 1)
    ]),
    (_ctx.settings[0].score_divisor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('darkstoreSettings.order_distribution')) + ":", 1),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t(`darkstoreSettings.${_ctx.settings[0].score_divisor.name}`)), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.settings[0].max_distance_from_dark_store)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('darkstoreSettings.max_distance_from_dark_store')) + ":", 1),
          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.settings[0].max_distance_from_dark_store ?? 'N/A'), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('darkstoreSettings.interval_time')) + ":", 1),
      _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.settings[0].interval_time ?? 'N/A'), 1)
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('darkstoreSettings.interval_based_order_weighting')) + ":", 1),
      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.settings[0].interval_weight ?? 'N/A'), 1)
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('darkstoreSettings.stop_auto_assignment')) + ":", 1),
      _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.settings[0].stop_auto_assignment ? _ctx.$t('yes') : _ctx.$t('no')), 1)
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('darkstoreSettings.waiting_time_at_the_darkstore_weight')) + ":", 1),
      _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.settings[0].waiting_time_at_the_darkstore_weight), 1)
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('darkstoreSettings.delivered_orders_distance_weight')) + ":", 1),
      _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.settings[0].delivered_orders_distance_weight), 1)
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('darkstoreSettings.delivered_orders_count_weight')) + ":", 1),
      _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.settings[0].delivered_orders_count_weight), 1)
    ])
  ]))
}